<template>
  <div v-if="userData !== ''">
    <!-- form Admin -->

    <b-row class="mt-0">
      <b-col md="12" class="mb-2">
        <h2>Personal Information</h2>
      </b-col>     
      <b-col md="4" class="mb-2">
        <label for="name">Name <span class="text-danger">*</span></label>
        <b-form-input id="name" v-model="userData.name" :maxlength="100" />
      </b-col>
      <b-col md="4" class="mb-2">
        <label for="Lastname">Lastname <span class="text-danger">*</span></label>
        <b-form-input id="Lastname" v-model="userData.lastname" :maxlength="100" />
      </b-col>
      <b-col md="4" class="mb-2">
        <label for="gender">Gender <span class="text-danger">*</span></label>
        <b-form-select id="gender" v-model="userData.gender" :options="gender">
          <option value="">Select Gender</option>
        </b-form-select>
      </b-col>
      <b-col md="3" class="mb-2">
        <label for="Phone number">Phone number <span class="text-danger">*</span></label>
        <cleave id="Phone_number" v-model="userData.phone_number" class="form-control" :raw="false"
          :options="optionsCleave.phone" placeholder="1234 567 8900" />
        <p class="text-right">
          <small><a @click="submitPhoneCode(userData.phone_number)" class="bg-primary text-light rounded"
              style="padding: 2px">Verify Phone</a></small>
        </p>
      </b-col>

      <b-col md="3">
        <b-form-group label="Phone is verified">
          <b-form-select v-model="userData.is_verified_phone" :options="optionsVerify">
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="3" class="mb-2">
        <label for="Phone number">Alternative Phone number 1</label>
        <cleave id="Phone_number" v-model="userData.alternaty_phone_number1" class="form-control" :raw="false"
          :options="optionsCleave.phone" placeholder="1234 567 8900" />
      </b-col>
      <b-col md="3" class="mb-2">
        <label for="Phone number">Alternative Phone number 2</label>
        <cleave id="Phone_number" v-model="userData.alternaty_phone_number2" class="form-control" :raw="false"
          :options="optionsCleave.phone" placeholder="1234 567 8900" />
      </b-col>
      <b-col md="3" class="mb-2">
        <label for="Phone number">Alternative Phone number 3</label>
        <cleave id="Phone_number" v-model="userData.alternaty_phone_number3" class="form-control" :raw="false"
          :options="optionsCleave.phone" placeholder="1234 567 8900" />
      </b-col>
      <b-col md="3" class="mb-2">
        <label for="email">Email <span class="text-danger">*</span></label>
        <b-form-input type="email" id="email" v-model="userData.email" :maxlength="100" />
        <p class="text-right">
          <small><a @click="submitEmailCode(userData.email)" class="bg-primary text-light rounded"
              style="padding: 2px">Verify Email</a></small>
        </p>
      </b-col>

      <b-col md="3">
        <b-form-group label="Email is verified">
          <b-form-select v-model="userData.is_verified_email" :options="optionsVerify">
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col md="4" class="mb-2">
        <label for="address">Address <span class="text-danger">*</span></label>
        <b-form-input id="address" v-model="userData.address" :maxlength="500" />
      </b-col>
      <b-col md="4" class="mb-2">
        <label for="status">Status <span class="text-danger">*</span></label>
        <b-form-select id="Status" v-model="userData.status" :options="option">
          <option value="">Select status</option>
        </b-form-select>
      </b-col>
      <b-col md="4" class="mb-2">
        <label for="status">Driver Type <span class="text-danger">*</span></label>
        <b-form-select id="Status" v-model="userData.driver_type" :options="driver_type" @change="onChange($event)">
          <option value="">Select type</option>
        </b-form-select>
      </b-col>
      <b-col md="12">
        <h2 class="mb-2">Bank Information</h2>
      </b-col>
      <b-col md="4">
        <b-form-group label="Type Account">
          <b-form-select id="gender" v-model="userData.account" :options="optionAccountType">
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Account number">
          <b-form-input v-model="userData.bank_account" :maxlength="50" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Aba routing number">
          <b-form-input v-model="userData.aba_routing" :maxlength="100" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Social security number">
          <b-form-input v-model="userData.social_security_number" :maxlength="100" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Bank name">
          <b-form-input v-model="userData.bank_institution" :maxlength="100" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Bank Address">
          <b-form-input v-model="userData.b_address" :maxlength="250" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Bank City">
          <b-form-input v-model="userData.b_city" :maxlength="50" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Bank State">
          <b-form-input v-model="userData.b_state" :maxlength="50" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Bank zip code">
          <b-form-input v-model="userData.b_zip" :maxlength="50" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Bank Telephone">
          <b-form-input v-model="userData.b_telephone" :maxlength="10" />
        </b-form-group>
      </b-col>




      <b-col v-if="userData.is_driver" md="4">
        <b-form-group label="Driver is a BT ?" class="mb-0">
          <validation-provider #default="{ errors }" rules="required">
            <span style="display: flex; gap: 10px">
              <b-form-radio v-model="userData.is_bt" name="Yes" value="1" class="custom-control-secondary"
                :state="errors.length > 0 ? false : null">
                Yes
              </b-form-radio>

              <!-- secondary -->
              <b-form-radio v-model="userData.is_bt" name="No" value="0" class="custom-control-secondary"
                :state="errors.length > 0 ? false : null">
                No
              </b-form-radio>
            </span>
            <small class="text-danger" v-if="errors[0]">This field is required</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="userData.show_provider">
      <b-col md="12" class="mb-2">
        <h2>Provider Information </h2>
      </b-col>
      <b-col md="4" class="mb-2">
        <label for="status">Provider<span class="text-danger">*</span></label>
        <b-form-select id="Status" placeholder="Select Provider" @change="onChangeProvider($event)">
          <option v-for="provider in listProviders" :key="provider.id"
            :value="provider.id + ',' + provider.account_number">
            {{ provider.company_name }}
          </option>
        </b-form-select>
      </b-col>
    </b-row>

    <!-- company information -->
    <div v-if="ratesFound && !userData.is_driver">
      <div class="d-flex">
        <h4 class="ml-50 mb-2 mt-2">Company Information</h4>
      </div>
      <b-row>
        <b-col md="4" class="mb-2">
          <label for="address">Company Name <span class="text-danger">*</span></label>
          <b-form-input disabled id="address" v-model="providerRates.provider_info.company_name" />
        </b-col>
        <b-col md="4" class="mb-2">
          <label for="address">Account Number <span class="text-danger">*</span></label>
          <b-form-input disabled id="address" v-model="userData.account_number" />
        </b-col>
        <b-col md="4" class="mb-2">
          <label for="address">Email <span class="text-danger">*</span></label>
          <b-form-input disabled id="address" v-model="providerRates.provider_info.email" />
        </b-col>
        <b-col md="4" class="mb-2">
          <label for="address">Phone <span class="text-danger">*</span></label>
          <b-form-input disabled id="address" v-model="providerRates.provider_info.phone" />
        </b-col>
      </b-row>
    </div>
    <!-- rates for provider -->
    <div v-if="ratesFound && !userData.is_driver">
      <div class="d-flex">
        <h4 class="ml-50 mb-2 mt-2">Rates</h4>
      </div>

      <b-row class="ml-4 mr-4">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Service Type</th>
              <th scope="col">Base Rate</th>
              <th scope="col">Price Per Hour Wait Time</th>
              <th scope="col">Cancellation Fee</th>
              <th scope="col">Additional Mileage</th>
              <th scope="col">City</th>
              <th scope="col">State</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(wheelchair, index) in providerRates.wc" :key="'wc' + index">
              <th scope="row"></th>
              <td>W/C</td>
              <td>
                <b-form-input disabled type="number" v-model="wheelchair.base_rate" />
              </td>
              <td>
                <b-form-input disabled type="number" v-model="wheelchair.wait_rate" />
              </td>
              <td>
                <b-form-input disabled type="number" v-model="wheelchair.cancel_rate" />
              </td>
              <td>
                <b-form-input disabled type="number" v-model="wheelchair.additional_mileage" />
              </td>
              <td>
                <b-form-input disabled type="text" v-model="wheelchair.state" />
              </td>
              <td>
                <b-form-input disabled type="text" v-model="wheelchair.city" />
              </td>
            </tr>
            <tr v-for="(ambulance, index) in providerRates.bls" :key="'bls' + index">
              <th scope="row"></th>
              <td>BLC</td>
              <td>
                <b-form-input disabled type="number" v-model="ambulance.base_rate" />
              </td>
              <td>
                <b-form-input disabled type="number" v-model="ambulance.wait_rate" />
              </td>
              <td>
                <b-form-input disabled type="number" v-model="ambulance.cancel_rate" />
              </td>
              <td>
                <b-form-input disabled type="number" v-model="ambulance.additional_mileage" />
              </td>
              <td>
                <b-form-input disabled type="text" v-model="ambulance.state" />
              </td>
              <td>
                <b-form-input disabled type="text" v-model="ambulance.city" />
              </td>
            </tr>
          </tbody>
        </table>
      </b-row>
    </div>
    <!--rate  for driver -->
    <div v-if="userData.is_driver">
      <div class="d-flex">
        <h2 class="ml-50 mb-2 mt-2">Rates driver</h2>
      </div>

      <b-row class="ml-4 mr-4">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Miles</th>
              <th scope="col">Trip</th>
              <th scope="col">Charges</th>
              <th scope="col">Additional</th>
            </tr>
          </thead>
          <tbody>
            <!-- 1 to 10 -->
            <tr>
              <th scope="row"></th>
              <td>1 to 10</td>
              <td>One Way Trip</td>
              <td><b-form-input v-model="driverData.m_1_to_10_oneway" :maxlength="15" /></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td>1 to 10</td>
              <td>Round Trip</td>
              <td><b-form-input v-model="driverData.m_1_to_10_twoway" :maxlength="15" /></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td>1 to 10</td>
              <td>With/2hr WT pre-authorized</td>
              <td><b-form-input v-model="driverData.m_1_to_10_with" :maxlength="15" /></td>
              <td>Include 2hr WT After $15 per hour</td>
            </tr>
            <tr class="blank_row">
              <td colspan="5"></td>
            </tr>
            <!-- 11 to 20 -->
            <tr>
              <th scope="row"></th>
              <td>11 to 20</td>
              <td>One Way Trip</td>
              <td><b-form-input v-model="driverData.m_11_to_20_oneway" :maxlength="15" /></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td>11 to 20</td>
              <td>Round Trip</td>
              <td><b-form-input v-model="driverData.m_11_to_20_twoway" :maxlength="15" /></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td>11 to 20</td>
              <td>With/2hr WT pre-authorized</td>
              <td><b-form-input v-model="driverData.m_11_to_20_with" :maxlength="15" /></td>
              <td>Include 2hr WT After $15 per hour</td>
            </tr>
            <tr class="blank_row">
              <td colspan="5"></td>
            </tr>
            <!-- 21 to 30 -->
            <tr>
              <th scope="row"></th>
              <td>21 to 40</td>
              <td>One Way Trip</td>
              <td><b-form-input v-model="driverData.m_21_to_40_oneway" :maxlength="15" /></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td>21 to 40</td>
              <td>Round Trip</td>
              <td><b-form-input v-model="driverData.m_21_to_40_twoway" :maxlength="15" /></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row"></th>
              <td>21 to 40</td>
              <td>With/2hr WT pre-authorized</td>
              <td><b-form-input v-model="driverData.m_21_to_40_with" :maxlength="15" /></td>
              <td>Include 2hr WT After $15 per hour</td>
            </tr>
          </tbody>
        </table>
      </b-row>
    </div>

    <b-row>
      <b-col md="12 save-btn">
        <button class="btn btn-primary" @click="setAccountData((type = 'save'))">
          Submit
        </button>
      </b-col>
    </b-row>


    <b-modal id="modal-center" centered hide-footer>
      <div class="d-block text-center">
        <h3>Enter 5 digits code received to user</h3>
        <b-col>
          <b-form-group>
            <b-form-input v-model="emailcode" @mouseover="changeColorCode(code)"
              v-bind:class="{ 'border border-danger': isCode == false }" />
          </b-form-group>
        </b-col>
        <h4>
          Make sure the contact checks also in the spam folder in case the email
          is not visible in the inbox.
        </h4>
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-button block @click="verifyEmailCode(userData.email, emailcode)">Verify</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('modal-center')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="modal-phone" centered hide-footer>
      <div class="d-block text-center">
        <h3>Enter 5 digits code received to user</h3>
        <b-col>
          <b-form-group>
            <b-form-input v-model="codePhone" @mouseover="changeColorCode(code)"
              v-bind:class="{ 'border border-danger': isCode == false }" />
          </b-form-group>
        </b-col>
      </div>
      <b-row class="mt-3">
        <b-col>
          <b-button block @click="verifyPhoneCode(codePhone, userData.phone_number)">Verify</b-button>
        </b-col>
        <b-col>
          <b-button block @click="$bvModal.hide('modal-center')">Close</b-button>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import { getMessageError } from "@core/utils/utils";
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormSelect,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Cleave from 'vue-cleave-component'
import { getAccountBankType } from "@core/utils/menus";

require('cleave.js/dist/addons/cleave-phone.us');


export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BFormSelect,
    BFormRadio,
    ValidationProvider,
    ValidationObserver,
    Cleave,
  },
  data() {
    return {
      src: "",
      btnupdate: false,
      userData: {
        name: "",
        lastname: "",
        gender: "",
        email: "",
        phone_number: "",
        address: "",
        status: "",
        driver_type: "",
        show_provider: false,
        account_number: "",
        provider_id: "",
        is_driver: false,
        rates: "",
        is_bt: "",
        alternaty_phone_number1: "",
        alternaty_phone_number2: "",
        alternaty_phone_number3: "",
        is_verified_email: "no",
        is_verified_phone: "no",
        bank_institution: "",
        b_address: "",
        b_city: "",
        b_state: "",
        b_zip: "",
        b_telephone: "",
        bank_account: "",
        aba_routing: "",
        account_type: "",
        social_security_number: "",
      },
      driverData: {},
      listProviders: "",
      providerRates: "",
      ratesFound: false,
      option: [
        {
          text: "Approved",
          value: "Approved",
        },
        {
          text: "Not Approved",
          value: "Not Approved",
        },
      ],
      gender: [
        {
          text: "Female",
          value: "Female",
        },
        {
          text: "Male",
          value: "Male",
        },
        {
          text: "Wish not to disclose",
          value: "Wish not to disclose",
        },
      ],
      driver_type: [
        {
          text: "CNA",
          value: "CNA",
        },
        {
          text: "Provider",
          value: "Provider",
        },
      ],
      optionsCleave: {
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      isEmail: true,
      isCode: true,
      code: "",
      codePhone: "",
      emailcode: "",
      driverData: {
        m_1_to_10_oneway: '35',
        m_1_to_10_twoway: '55',
        m_1_to_10_with: '85',
        m_11_to_20_oneway: '55',
        m_11_to_20_twoway: '75',
        m_11_to_20_with: '95 to 110',
        m_21_to_40_oneway: '75',
        m_21_to_40_twoway: '95',
        m_21_to_40_with: '125 to 135',
      },
      optionsVerify: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      optionAccountType:[],
    };
  },
  methods: {
    getProviders() {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .get(`admin/panel/providerslistdropdown`)
        .then((response) => {
          this.listProviders = response.data.data.reverse();
          this.$swal.close();
        })
        .catch((res) => console.log(res.response.data));
    },
    providerRatesFn(id) {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .get(`admin/panel/providerrates/${id}`)
        .then((response) => {
          this.providerRates = response.data.data;
          console.log(this.providerRates);
          this.$swal.close();
          if (this.providerRates != null) this.ratesFound = true;
          else this.ratesFound = false;
        })
        .catch((res) => console.log(res.response.data));
    },
    async PhoneDriverExist(phone) {
      let res = false;
      let formData = new FormData();
      formData.append("phone_number", phone);
      await this.$http
        .post("auth/driver/phone_driver_exist", formData)
        .then((response) => {
          res = response.data.data
        }).catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
      return res;
    },
    async IsValidaEmail(email) {
      let res = false;
      let formData = new FormData();
      formData.append("email", email);
      await this.$http
        .post("auth/is_valid_email", formData)
        .then((response) => {
          res = response.data.data
        }).catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
      return res;
    },
    async setAccountData() {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      let res = await this.PhoneDriverExist(this.userData.phone_number);
      if (this.userData.phone_number == '' || this.userData.phone_number == null) {
        this.$swal({
          title: 'Phone is required',
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }
      if (res == true) {
        this.$swal({
          title: 'Driver phone already exists',
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }
      res = await this.IsValidaEmail(this.userData.email);
      if (res == false) {
        this.$swal({
          title: 'Email is not valid',
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }

      if(this.userData.phone_number.trim().length!=12)
      {
        this.$swal({
          title: 'Phone driver is not valid',
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }

      if (this.listProviders == null) {
        this.$swal({
          title:
            "There are no providers registered in the system.At least a provider must be registered in the system.",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return false;
      }

      if (
        (this.userData.name === "" ||
          this.userData.lastname === "" ||
          this.userData.gender === "" ||
          this.userData.email === "" ||
          this.userData.address === "" ||
          this.userData.status === "" ||
          this.userData.driver_type === "")
      ) {
        this.$swal({
          title: "Please fill out required fields",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

      } else {
        this.userData.rates = this.driverData;

     

        this.$emit("getAccountData", this.userData);
      }
    },
    onChange(event) {
      if (event == "Provider") {
        this.userData.show_provider = true;
        this.userData.is_driver = false;
      }
      if (event == "CNA") {
        this.userData.show_provider = false;
        this.userData.is_driver = true;
      }
    },
    onChangeProvider(event) {
      let data = event.split(",");

      this.userData.provider_id = data[0];
      this.userData.account_number = data[1];
      this.providerRatesFn(this.userData.provider_id);
    },
    submitPhoneCode(phone) {
      if (phone === null || phone.trim() == "") {
        return false;
      }

      let formData = new FormData();
      formData.append("phone_number", phone);

      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$http
        .post("admin/panel/verify/sendverificationphonecodedriver", formData)
        .then((res) => {
          this.$swal.close();
          if (res.data.status === 200) {
            this.$bvModal.show("modal-phone");
          }
        })
        .catch((error) => {
          this.$swal({
            title: getMessageError(error),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    submitEmailCode(email) {
      if (email == "") {
        return false;
      } else {

        this.$swal({
          title: "Please, wait...",
          didOpen: () => {
            this.$swal.showLoading();
          },
        });

        let formData = new FormData();
        formData.append("email", email);

        this.$http
          .post("admin/panel/verify/sendverificationemailcodedriver", formData)
          .then((res) => {
            this.$swal.close();
            if (res.data.status === 200) {
              this.$bvModal.show("modal-center");
            }
          })
          .catch((error) => {
            this.$swal({
              title: getMessageError(error),
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    verifyPhoneCode(codePhone, phone_number) {
      let formData = new FormData();
      formData.append("code", codePhone);
      formData.append("phone_number", phone_number);

      this.$http
        .post("admin/panel/validateverificationphonecodedriver", formData)
        .then((res) => {
          if (res.data.status === 200) {
            this.userData.is_verified_phone = "yes";
            this.$bvModal.hide("modal-center");
            this.$swal({
              title: res.data.message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.codePhone = "";
          }
        })
        .catch((res) => {
          this.userData.is_verified_phone = "no";
          this.$swal({
            title: getMessageError(res),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    verifyEmailCode(email, code) {
      if (this.emailcode == "") {
        return false;
      } else {
        let formData = new FormData();
        formData.append("code", code);
        formData.append("email", email);
        this.$http
          .post("admin/panel/validateverificationemailcodedriver", formData)
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              this.userData.is_verified_email = "yes";
              this.$bvModal.hide("modal-center");
              this.$swal({
                title: res.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
          .catch((res) => {
            this.userData.is_verified_email = "no";
            this.$swal({
              title: getMessageError(res),
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    changeColorCode() {
      this.isCode = true;
    },
    changeColor() {
      this.isEmail = true;
    },
  },
  mounted() {
    this.optionAccountType = getAccountBankType();
    this.getProviders();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.text-name-driver {
  font-size: 1.5rem;
  margin-bottom: 0 !important;
}

.backgroundProfile-driver {
  background-color: $primary;
}

.save-btn {
  margin: auto;
  text-align: right;
}

.blank_row {
  height: 50px !important;
  background-color: #ffffff;
}
</style>